<template>
  <div class="m-3 wrapper  wrapper--default">
    <h3 class="text-center">Rapporteer een near-miss</h3>
    <hr class="m-3">
    <form id="nearmiss_form" action="POST" enctype="multipart/form-data">
      <div class="flex w100 flexbox">

        <div class="m2 flex-grow-1">
          <label><strong>Wanneer is het voorgevallen? (gebruik kalender pictogram)</strong></label>
          <input type="date" class="form-control label" v-model="when_observed" id="date-only-picker" v-on:click="disableInput" />
          <TextInput label="Duur observatie" v-model="duration_observed" name="duration_observed"
            placeholder="Licht hier toe hoelang je observatie hebt gemaakt..." />
          <fieldset class="">
            <Legend>Waar is het voorgevallen?</Legend>
            <select id="location" @change="updateSelectionList">
              <option value="placeholder" disabled selected>Please choose an option</option>
              <option value="Koornwaard">Koornwaard</option>
              <option value="Werkplaats">Werkplaats</option>
              <option value="Poetsers">Poetsers</option>
              <option value="Kantoor">Kantoor</option>
              <option value="Terrein">Terrein</option>
              <option value="Binnenkomst">Binnenkomst</option>
              <option value="TP1919">TP1919</option>
            </select>
            <select id="location_specification" hidden>
            </select>
          </fieldset>
          <fieldset>
            <Legend>Wat is er voorgevallen?</Legend>
            <div v-for="(item, key) in options_column_2" :key="key">
              <input type="radio" :id="item.value" :value="item.value" name="incident">
              <label :for="item.value" class="ml-2">{{ item.text }}</label>
            </div>
          </fieldset>
        </div>
        <div class="m2 flex-grow-1">
          <div class="flexbox _column">
            <textarea id="textarea" v-model="situation_description"
              placeholder="Geef een duidelijke beknopte beschrijving van de situatie..." rows="3" max-rows="6"
              input-id="text_vak" class="employee_textarea m2" name="situation_description" />
            <textarea id="textarea" v-model="solution_ideas" placeholder="Mogelijke oplossingen?" rows="3" max-rows="6"
              input-id="text_vak" class="employee_textarea m2" name="solution_ideas" />
            <input id="image-file" type="file" class="m-2" name="bestanden[]" multiple />
            <div class="m2">
              <h3>Contact?</h3>
              <input type="checkbox" id="anonymity" name="anonymity" @click="disableContactField()" />
              <label for="anonymity" class="ml-2">Anoniem blijven?</label>
              <fieldset id="contact_fieldset">
                <div v-for="(item, key) in options_column_3" :key="key">
                  <input type="radio" :id="item.value" :value="item.value" name="contact">
                  <label for="contact" class="ml-2">{{ item.text }}</label>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </form>
    <button v-on:click="submitNearMiss()" class="m2 employee_add--button" id="submit_button">
      Verzenden
    </button>
    <loading v-if="loading_submit" class="p-3" />
  </div>
</template>
<style scoped>
input[type="date"] {
  pointer-events: auto;
  /* Allow pointer (mouse) events */
}
</style>
<script>
import hasIndex from '@/functions/hasIndex'
import TextInput from "@/components/TextInput";
import { msalInstance } from "vue-msal-browser";


export default {
  components: { TextInput },
  data: () => ({
    options_column_1: {
      'Koornwaard': ['Koornwaard Kantoor', 'Koornwaard Terrein'],
      'Werkplaats': ['Werkplaats Trucks', 'Werkplaats Vans', 'Keurcentrum Trucks', 'Keurcentrum Vans', 'Carrosseriehal'],
      'Poetsers': ['Washal', 'Poetshal'],
      'Kantoor': ['Kantoren Trucks', 'Kantoren Vans', 'Kantoren TP'],
      'Terrein': ['Terrein Vans', 'Terrein Trucks', 'Terrein TP'],
      'Binnenkomst': ['Binnenkomst kantoor', 'Binnenkomst terrein'],
      'TP1919': ['TP1919 Magazijn (Nieuw)', 'TP1919 Magazijn (Gebruikt)', 'Demontagehal'],
    },
    options_column_2: [
      { text: "Voorval zonder lichamelijk letsel, maar waarbij het risico op letsel duidelijk aanwezig was", value: "1" },
      { text: "Onveilig werk of onveilige werkomstandigheden", value: "2" },
      { text: "Onveilige werkgewoonte / procedure", value: "3" },
      { text: "Onveilig of onbetamelijk gedrag", value: "4" },
      { text: "Onveilig / onjuist gebruik van gereedschap", value: "5" },
      { text: "Slecht werkend gereedschap", value: "6" },
      { text: "Anders (Licht toe in situatie vak)", value: "7" }
    ],
    options_column_3: [
      { text: "Ik wens op de hoogte gehouden te worden over hier uitvloeiende acties", value: "1" },
      { text: "Ik wens niet op de hoogte gehouden te worden over hier uitvloeiende acties", value: "0" },
    ],
    submitted: false,
    loading_submit: false,
    when_observed: null,
    duration_observed: null,
    description: null,
    solution_ideas: null,
    anonymity: false,
    situation_description: null,
    location_observed: null
  }),
  methods: {
    disableInput() {
      const dateInput = document.getElementById('date-only-picker');
      dateInput.addEventListener('keydown', (event) => {
        event.preventDefault();
      });
    },

    disableContactField() {
      const checkbox = document.getElementById('anonymity')
      const contact_fieldset = document.getElementById('contact_fieldset')
      const radioGroup = document.querySelectorAll('input[name="contact"]')
      if (checkbox.checked) {
        contact_fieldset.disabled = true
        radioGroup.forEach(radio => radio.checked = false)
        this.anonymity = true
      } else {
        contact_fieldset.disabled = false
        this.anonymity = false
      }
    },

    updateSelectionList() {
      if (document.querySelector('option[value=placeholder]')) {
        document.getElementById('location').removeChild(document.querySelector('option[value=placeholder]'))
      }
      const specification_array = document.getElementById('location_specification');
      specification_array.hidden = false;

      specification_array.innerHTML = '';

      const specification = this.options_column_1[document.getElementById('location').value];

      specification.forEach(item => {
        const option = document.createElement("option");
        option.value = item.toLowerCase();
        option.textContent = item;
        specification_array.appendChild(option)
      })
    },

    submitNearMiss() {
      document.querySelector('#submit_button').disabled = true;
      document.querySelector('#submit_button').style.background = 'lightgray';
      const form = document.getElementById('nearmiss_form')
      const incident_checked = document.querySelector('input[type="radio"][name="incident"]:checked');
      const contact_checked = document.querySelector('input[type="radio"][name="contact"]:checked');

      if (this.when_observed == null) {
        this.$store.dispatch("addNotification", {
          message: "Wanneer is niet ingevuld",
          type: "danger",
        });
        document.querySelector('#submit_button').disabled = false;
        document.querySelector('#submit_button').style.background = '#28a745';
      } else if (this.duration_observed == null) {
        this.$store.dispatch("addNotification", {
          message: "Duur is niet ingevuld",
          type: "danger",
        });
        document.querySelector('#submit_button').disabled = false;
        document.querySelector('#submit_button').style.background = '#28a745';
      } else if (document.getElementById('location_specification').value == '') {
        this.$store.dispatch("addNotification", {
          message: "Locatie is niet ingevuld",
          type: "danger",
        });
        document.querySelector('#submit_button').disabled = false;
        document.querySelector('#submit_button').style.background = '#28a745';
      } else if (!incident_checked) {
        this.$store.dispatch("addNotification", {
          message: "Incident is niet ingevuld",
          type: "danger",
        });
        document.querySelector('#submit_button').disabled = false;
        document.querySelector('#submit_button').style.background = '#28a745';
      } else if (this.situation_description == null) {
        this.$store.dispatch("addNotification", {
          message: "situatiebeschrijving is niet ingevuld",
          type: "danger",
        });
        document.querySelector('#submit_button').disabled = false;
        document.querySelector('#submit_button').style.background = '#28a745';
      } else if (!contact_checked && this.anonymity == false) {
        this.$store.dispatch("addNotification", {
          message: "Contact is niet ingevuld",
          type: "danger",
        });
        document.querySelector('#submit_button').disabled = false;
        document.querySelector('#submit_button').style.background = '#28a745';
      } else {
        const incident = document.querySelector('input[type="radio"][name="incident"]:checked').value;
        const location_observed = document.getElementById('location_specification').value;
        const contact = this.anonymity == false ? document.querySelector('input[type="radio"][name="contact"]:checked').value : 0;

        var headers = new Headers();
        headers.append("KLEYN-APIKEY", process.env.VUE_APP_APIKEY);

        let date = new Date(this.when_observed)
        let localDateString = date.toLocaleString('nl-NL', { hour12: false }).replace(',', '')

        var data = new FormData(form);
        if (this.anonymity == false) {
          data.append("observer_name", msalInstance.getAllAccounts()[0].idTokenClaims.name);
          data.append("observer_email", msalInstance.getAllAccounts()[0].idTokenClaims.preferred_username);
        }
        data.append("when_observed", localDateString);
        data.append("location", location_observed);
        data.append("incident", incident);
        data.append("contact", contact);
        data.append("anonymity", this.anonymity ? 1 : 0);

        console.log(localDateString);

        var requestOptions = {
          method: "POST",
          headers: headers,
          body: data,
        };

        fetch(`${process.env.VUE_APP_API_URL}near-miss/post-incident`, requestOptions)
          .then((response) => response.json())
          .then((responseJson) => {
            if (hasIndex(responseJson.message, "Near-Miss gerapporteerd")) {
              const radioInputs = document.querySelectorAll('input[type="radio"]');
              radioInputs.forEach(radio => radio.checked = false);
              this.loading_submit = false;
              this.when_observed = null;
              this.duration_observed = null;
              this.solution_ideas = null;
              this.situation_description = null;
              this.$store.dispatch("addNotification", {
                message: responseJson.message,
                type: "success",
              });
              this.submitted = false;
              document.querySelector('#submit_button').disabled = false;
              document.querySelector('#submit_button').style.background = '#28a745';
            } else {
              this.submitted = false;
              this.loading_submit = false;
              document.querySelector('#submit_button').disabled = false;
              document.querySelector('#submit_button').style.background = '#28a745';
              this.$store.dispatch("addNotification", {
                message: responseJson.error,
                type: "danger",
              });
            }
          });
      }
    },
  },
};
</script>