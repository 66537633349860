<template>
  <div>
    <label v-if="label" class="lab" :for="id">{{ label }}</label>
    <div class="rel">
      <input
        :id="id"
        ref="input"
        v-bind="$attrs"
        class="inp"
        :class="[
          {
            inperror: errors.length,
            inpicon: withIcon === true,
          },
        ]"
        :type="type"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @keydown="$emit('keydown', $event)"
        @blur="$emit('blur', $event)"
        @keyup="$emit('keyup', $event)"
      />
      <div v-if="errors.length" class="inperrtxt">
        {{ errors[0] }}
      </div>

      <svg
        class="inperricon"
        style="top: 12px; right: 12px"
        v-if="errors.length"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M11.953,2C6.465,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.493,2,11.953,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"
        />
      </svg>

      <div class="inpiconcont" v-if="withIcon">
        <slot name="icon"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextInput",

  inheritAttrs: false,

  props: {
    id: {
      type: String,
      default() {
        return `text-input-${this._uid}`;
      },
    },
    type: {
      type: String,
      default: "text",
    },
    value: String,
    label: String,
    errors: {
      type: Array,
      default: () => [],
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end);
    },
  },
};
</script>
<style>
.rel {
  position: relative;
}

.lab {
  margin-bottom: 0.25rem;
  font-weight: 600;
  display: block;
}

.inp {
  padding: 0.25rem;
  height: 3rem;
  display: block;
  width: 100%;
  background-color: white;
  border-radius: 0.25rem;
}

.inperrtxt {
  margin-top: 0.25rem;
  color: #e53e3e;
  font-size: 0.875rem;
}

.inperricon {
  position: absolute;
  fill: currentColor;
  color: #e53e3e;
}

.inperror {
  border-color: #fc8181;
}

.inpicon {
  padding-left: 3rem;
}

.inpiconcont {
  position: absolute;
  width: 2.5rem;
  left: 0;
  bottom: 0;
  top: 0;
  margin-left: 0.5rem;
  display: block;
}
</style>
